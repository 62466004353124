import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import TrackingService from "../../services/trackingService";
import { Map } from "./map";
import { Marker } from "./marker";
import {Buffer} from 'buffer';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Tracker = (props) => {

    const service = TrackingService();
    
    const queryStr = useQuery();
    const [stateInterval, setStateInterval] = useState(null);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [zoom, setZoom] = React.useState(16); // initial zoom
    const [center, setCenter] = React.useState({
      lat: 0,
      lng: 0,
    });

    const [markerPosition, setMarkerPosition] = React.useState({
        lat: 0,
        lng: 0,
    });



    const updateVehiclePosition = (isFirstLoad) => {
        const token = queryStr.get("tk");
        const isTknExpired = (Date.now() >= JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString()).exp * 1000)
        if (!isTknExpired) {
            service.getVehicle(token).then(result => {
                if(result.error){
                    if (result.status === 401) {
                        props.history.push("/");
                        clearInterval(stateInterval);

                    }
                }else{
                    // if(isFirstLoad) {
                        setCenter({
                            lat: result.gps.latitude,
                            lng: result.gps.longitude
                        });
                    // }
                    setMarkerPosition({
                        lat: result.gps.latitude,
                        lng: result.gps.longitude
                    });
                }
            });
        }else{
            setIsTokenExpired(isTknExpired);
        }
    }

    useState(() => {
        // check provided token
        const token = queryStr.get("tk");
        const isTknExpired = (Date.now() >= JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString()).exp * 1000)

        setIsTokenExpired(isTknExpired);

        if(!isTknExpired) {
            updateVehiclePosition(true);
            const int = setInterval(updateVehiclePosition, 15000);
            setStateInterval(int);
        }
    }, [queryStr]);

    return (
        <>
            { isTokenExpired ? 
            <h3 className="text-center text-danger mt-5">Tracking has expired</h3> : 
            <Map
            center={center}
            zoom={zoom}
            style={{ flexGrow: "1", minHeight: "100%" }}>
                <Marker position={markerPosition} />
            </Map>
            }
        </>
    );
};
