import "./App.scss";
import { Container, Spinner } from "react-bootstrap";
import { Header } from "./components/common/header";
import { Tracker } from "./components/maps/tracker";
import { Routes, Route } from "react-router-dom";
import { Dispatcher } from "./components/dispatcher/dispatcher";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { ErrorBoundary } from "react-error-boundary";
import { AuthenticatedTemplate } from "@azure/msal-react";

export default function App() {
  const render = (status) => {
    if(status === Status.LOADING) return <Spinner />;
    if (status === Status.FAILURE) return <> Error </>;
    if (status === Status.UNKNOWN) return <> Unknown </>;
  };

  function ErrorFallback({error, resetErrorBoundary}) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    )
  }

  return (
    <>
        <Header />
        <Container fluid className="main-content d-flex justify-content-center p-0">
          <Routes>
            <Route path="/" index element={
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <AuthenticatedTemplate>
                  <Dispatcher />
                </AuthenticatedTemplate>
              </ErrorBoundary>} />
            <Route path="/public" element={
              <Wrapper className="min-vh-100" apiKey="AIzaSyDrhy6agablK1-sYiBCiXqFeoNSA1yJ4HM" render={render}>
                <Tracker />
              </Wrapper>
            } />
          </Routes>
        </Container>
    </>
  );
}
