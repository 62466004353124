import http from 'axios';

const TrackingService = () => {

    const publicClient = http.create();

    const getVehicle = async (token) => {
        let url = process.env.REACT_APP_API_URL + '/vehicle';

        try {
            const res = await publicClient.get(url, { headers: { Authorization: token, 'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_SUB_KEY } });
            return res.data.data[0];
        } catch (err) {
            if(err.response.status === 401) {
                return {error: true, message: 'Unauthorized', status: 401};
            }
            console.error(err);
            throw err;
        }
    }

    const getDrivers = async () => {
        let url = process.env.REACT_APP_API_URL + '/drivers';
        try {
            const res = await http.get(url);
            return res.data.data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

    const sendTrackingLink = async (vehicleId, mobileNumber) => {
        let url = process.env.REACT_APP_API_URL + '/sendtrackinglink';
        try {
            const res = await http.post(url, {"vehicleId": vehicleId, "mobileNumber": mobileNumber});
            return res.data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

    return { getVehicle, getDrivers, sendTrackingLink };
}

export default TrackingService;