import React from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import axios from 'axios';
import { protectedResources } from '../../services/authConfig';

export const RequestInterceptor = (props) => {
  const { instance, accounts } = useMsal();

  const account = useAccount(accounts[0]);

  /* eslint-disable no-param-reassign */
  axios.interceptors.request.use(async (config) => {
 
    if (!account) {
      throw Error('No active account! Verify a user has been signed in.');
    }

    const response = await instance.acquireTokenSilent({
      ...protectedResources,
      account,
    });

    const bearer = `Bearer ${response.accessToken}`;
    config.headers.Authorization = bearer;

    config.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_APIM_SUB_KEY;

    return config;
  });
  /* eslint-enable no-param-reassign */

  return (
    <>
      {props.children}
    </>
  );
};