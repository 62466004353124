import React, { useEffect, useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../services/authConfig";
import { callMsGraph } from "../../services/graph";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { Container, Dropdown, Navbar, NavLink, Nav } from "react-bootstrap";

import "./header.scss";
import { useLocation } from "react-router-dom";


const AuthComponent = () => {
  let { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  useEffect(() => {
    if(!graphData) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          callMsGraph(response.accessToken).then((resp) => setGraphData(resp));
        });
    }
  }, [accounts, graphData, instance]);

  return (
    <Dropdown>
      <Dropdown.Toggle className="link-white" as={NavLink}>
        {accounts && accounts[0]?.name}
      </Dropdown.Toggle>
      <Dropdown.Menu className="bg-dark">
        {" "}
        <SignOutButton />{" "}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export const Header = () => {
  let location = useLocation();

  return (
    <Navbar expand="md" bg="dark" variant="dark">
      <Container fluid>
        <Navbar.Brand href="#home">
          <img
            src="/logo-sign.png"
            className="d-inline-block align-top logo mx-3"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <AuthenticatedTemplate>
            <AuthComponent />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            {location.pathname === "/" &&  <SignInButton />}
          </UnauthenticatedTemplate>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
