import "./dispatcher.scss";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import PhoneInput from "react-phone-number-input/input";
import { Link } from "react-router-dom";
import TrackingService from "../../services/trackingService";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import en from "react-phone-number-input/locale/en";
import { RequestInterceptor } from "../common/RequestInterceptor";

export const Dispatcher = () => {
  const service = TrackingService();

  const [state, setState] = useState({
    vehicleId: 0,
    mobileNumber: "",
    drivers: [],
    country: "IE",
  });

  const [token, setToken] = useState("");

  useEffect(() => {
    if (state?.drivers?.length === 0) {
      service.getDrivers().then((drivers) => {
        setState({ ...state, drivers: drivers });
      });
    }
  }, [state, service]);

  const handleChange = function (ev) {
    let fieldName = ev.target.name;
    let fleldVal = ev.target.value;
    setState({ ...state, [fieldName]: fleldVal });
  };

  const handleSubmit = function (ev) {
    ev.preventDefault();

    service
      .sendTrackingLink(state.vehicleId, state.mobileNumber)
      .then((res) => {
        setToken(res);
        setTimeout(() => {
          setToken("");
          setState({ ...state, vehicleId: 0, mobileNumber: "", drivers: [] });
        }, 30000);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    
      <RequestInterceptor>
        <Form onSubmit={handleSubmit} className="mt-4">
          <h3 className="text-center">Send a tracking link</h3>
          <Row>
            <Col xs="auto">
              <Form.Select
                defaultValue={state?.vehicleId}
                onChange={handleChange}
                name="vehicleId"
              >
                <option value="0">Driver...</option>
                {state?.drivers?.map((driver) => (
                  <option
                    key={driver.id}
                    disabled={!driver.staticAssignedVehicle}
                    value={driver.staticAssignedVehicle?.id}
                  >
                    {driver.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              <InputGroup>
                <Dropdown
                  onSelect={(selectedCountry) => {
                    setState({ ...state, country: selectedCountry });
                  }}
                >
                  <Dropdown.Toggle variant="primary">
                    {state?.country}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu-scroll">
                    {getCountries().map((country) => (
                      <Dropdown.Item key={`_${country}`} eventKey={country}>
                        {en[country]} +{getCountryCallingCode(country)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <PhoneInput
                  placeholder="Enter mobile number"
                  defaultCountry="IE"
                  className="form-control"
                  value={state?.mobileNumber}
                  country={state?.country}
                  onChange={(v) => {
                    setState({ ...state, mobileNumber: v });
                  }}
                />
              </InputGroup>
            </Col>
            <Col xs="auto">
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center m-3">
            <Col xs="auto">
              <Alert variant="success" show={token ? true : false} dismissible>
                SMS sent with success. <br />
                <Link to={`/public?tk=${token}`}>Go to tracking</Link>
              </Alert>
            </Col>
          </Row>
        </Form>
      </RequestInterceptor>
    
  );
};
